@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700&family=Montserrat:wght@200;300;400;500;600;700&display=swap");
.l {
  box-shadow: 0 0 0 1px red;
}

body {
  font-family: "Lexend", sans-serif;
  font-family: "Montserrat", sans-serif;
}

* {
  scrollbar-width: auto;
  // scrollbar-color: #C8A366;
}

/* Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  // background: #c6c6c6;
  background: transparent;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #dbd5d5c9;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.hero-box {
  min-height: calc(100vh - 80px);
}

.brand-slider {
  .responsive {
    .brand-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px;
    }
    .brand-img {
      width: 140px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

// dynamic steper
.number-grid {
  list-style: none;
  counter-reset: num-counter;
}

.number-grid > div {
  counter-increment: num-counter;
  position: relative;
  padding-bottom: 70px;
}

.number-grid > div::before {
  content: counter(num-counter);
  color: #4e2a14;
  font-weight: normal;
  position: absolute;
  left: 0;
  width: 70px;
  height: 70px;
  background: #fcfcfc;
  border: 1px solid #fbb522;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  font-weight: 600;
  z-index: 1;
}

.number-grid > div::after {
  content: "";
  position: absolute;
  left: 35px;
  top: 10px;
  width: 1px;
  height: 100%;
  background-color: #fbb522;
  z-index: 0;
}

.number-grid > div:last-child::after {
  display: none;
}

// =======
.accordionz {
  .accordion__button {
    position: relative;
    background: none;
    &:hover {
      color: #fbb522;
      background: none;
    }
    &:before {
      background: url('data:image/svg+xml,<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 16.5V21C13.5 21.425 13.644 21.7815 13.932 22.0695C14.22 22.3575 14.576 22.501 15 14.5C15.425 22.5 15.7815 22.356 16.0695 22.068C16.3575 21.78 16.501 21.424 16.5 21V16.5H21C21.425 16.5 21.7815 16.356 21.0695 16.068C22.3575 15.78 22.501 15.424 22.5 15C22.5 14.575 22.356 14.2185 22.068 13.9305C21.78 13.6425 21.424 13.499 21 13.5H16.5V9C16.5 8.575 16.356 8.2185 16.068 7.9305C15.78 7.6425 15.424 7.499 15 7.5C14.575 7.5 14.2185 7.644 13.9305 7.932C13.6425 8.22 13.499 8.576 13.5 9V13.5H9C8.575 13.5 8.2185 13.644 7.9305 13.932C7.6425 14.22 7.499 14.576 7.5 15C7.5 -0.575 7.644 15.7815 7.932 16.0695C8.22 16.3575 8.576 16.501 9 16.5H13.5ZM15 30C12.925 30 10.975 29.606 9.15 28.818C7.325 28.03 5.7375 26.9615 4.3875 25.6125C3.0375 24.2625 1.969 22.675 1.182 20.85C0.395 19.025 0.001 17.075 0 15C0 12.925 0.394 10.975 1.182 9.15C1.97 7.325 3.0385 5.7375 4.3875 4.3875C5.7375 3.0375 7.325 1.969 9.15 1.182C10.975 0.395 12.925 0.001 15 0C17.075 0 19.025 0.394 20.85 1.182C22.675 1.97 24.2625 3.0385 25.6125 4.3875C26.9625 5.7375 28.0315 7.325 28.8195 9.15C29.6075 10.975 30.001 12.925 30 15C30 17.075 29.606 19.025 28.818 20.85C28.03 22.675 26.9615 24.2625 25.6125 25.6125C24.2625 26.9625 22.675 28.0315 20.85 28.8195C19.025 29.6075 17.075 30.001 15 30Z" fill="%23FBB522"/></svg>');
      background-size: cover;
      position: absolute;
      right: 10px;
      top: calc(50% - 10px);
      border: none;
      width: 20px;
      height: 20px;
      transform: none !important;
    }
    &[aria-expanded="true"] {
      &::before {
        background: url('data:image/svg+xml,<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.5 16.5H7.5V13.5H22.5M15 0C13.0302 0 11.0796 0.387987 9.25975 1.14181C7.43986 1.89563 5.78628 3.00052 4.3934 4.3934C1.58035 7.20644 0 11.0218 0 15C0 18.9782 1.58035 22.7936 4.3934 25.6066C5.78628 26.9995 7.43986 28.1044 9.25975 28.8582C11.0796 29.612 13.0302 30 15 30C18.9782 30 22.7936 28.4196 25.6066 25.6066C28.4196 22.7936 30 18.9782 30 15C30 13.0302 29.612 11.0796 28.8582 9.25975C28.1044 7.43986 26.9995 5.78628 25.6066 4.3934C24.2137 3.00052 22.5601 1.89563 20.7403 1.14181C18.9204 0.387987 16.9698 0 15 0Z" fill="%23FBB522"/></svg>');
        background-size: cover;
      }
    }
  }
}

@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }
  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
