@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap");
body {
  font-family: "Montserrat", sans-serif;
}

.l {
  box-shadow: inset 0 0 0 1px red;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-45 {
  font-size: 45px !important;
}

.fs-50 {
  font-size: 50px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-1200 {
  font-weight: 1000 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.txt-brown {
  color: #4e2a14;
}

.bg-yellow {
  background: #fbb522;
}

.cursor-pointer {
  cursor: pointer;
}

.icon {
  stroke: unset;
  stroke-width: 0px;
  fill: currentColor;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
}

.drop-header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
}
.drop-header .navbar {
  width: 100%;
  background: #fff6e4;
  padding-top: 0;
  padding-bottom: 0;
}
.drop-header .navbar .navbar-brand {
  height: 70px;
}
.drop-header .navbar .navbar-brand img {
  width: 100%;
  height: 100%;
}

.br-right {
  border-right: 1px solid #fbb522;
  padding-right: 12px;
}

.log-singbtn a:hover,
.log-singbtn a :active {
  color: #fbb522;
}

.nav {
  display: inline-flex;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding: 0 20px;
  border-radius: 10px;
  height: auto;
}
.nav .nav-item {
  color: #83818c;
  padding: 25px;
  text-decoration: none;
  transition: 0.3s;
  margin: 0 6px;
  z-index: 1;
  font-weight: 600;
  font-size: 20px;
  position: relative;
}
.nav .nav-item:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: #dfe2ea;
  border-radius: 8px;
  opacity: 0;
  transition: 0.3s;
}
.nav .nav-item:not(.active):hover:before {
  opacity: 1;
  bottom: 0;
}
.nav .nav-item:not(.active):hover {
  color: #333;
}
.nav .nav-item:nth-child(1).active {
  color: orange;
}
.nav .nav-item:nth-child(1).active ~ .nav-indicator {
  background-color: orange;
  width: 84.69px;
  left: 26px;
}
.nav .nav-item:nth-child(2).active {
  color: orange;
}
.nav .nav-item:nth-child(2).active ~ .nav-indicator {
  background-color: orange;
  width: 160px;
  left: 122.69px;
}
.nav .nav-item:nth-child(3).active {
  color: orange;
}
.nav .nav-item:nth-child(3).active ~ .nav-indicator {
  background-color: orange;
  width: 78px;
  left: 298.35px;
}
.nav .nav-item:nth-child(4).active {
  color: orange;
}
.nav .nav-item:nth-child(4).active ~ .nav-indicator {
  background-color: red;
  width: 71.86px;
  left: 367.57px;
}
.nav .nav-item:nth-child(5).active {
  color: purple;
}
.nav .nav-item:nth-child(5).active ~ .nav-indicator {
  background-color: purple;
  width: 101.77px;
  left: 451.43px;
}

.nav-indicator {
  position: absolute;
  left: 0;
  bottom: 0;
  transition: 0.4s;
  height: 8px;
  z-index: 1;
  border-radius: 8px;
}

.dropdown-box .nav-item,
.dropdown-box .dropdown {
  font-size: 20px;
  font-weight: 600;
  color: #83818c;
}

@media (max-width: 580px) {
  .nav {
    overflow: auto;
  }
}
.hero-box {
  width: 100%;
  min-height: calc(100vh - 80px);
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}
.hero-box .hero-poster {
  opacity: 0.55;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.hero-box .login-box {
  width: 600px;
  background: #ffffff;
  box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 20px 30px;
  position: relative;
  z-index: 1;
}
.hero-box .login-form {
  width: 500px;
  background: #ffffff;
  box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 20px 40px;
  position: relative;
  z-index: 1;
}
.hero-box .login-form .logo-box {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.hero-box .login-form .logo-box .logo {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.icon-btn {
  background: none;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  height: 50px;
  font-size: 22px;
  font-weight: 500;
}
.icon-btn:hover {
  border: 1.2px solid #fbb522;
}

.google-btn {
  border: 1.2px solid #4175df;
  color: #4175df;
}

.fb-btn {
  border: 1.2px solid #006494;
  color: #006494;
}

.linkedin-btn {
  border: 1.2px solid #0077b7;
  color: #0077b7;
}

.email-btn {
  border: 1.2px solid #d73939;
  color: #d73939;
}

.sign-btn {
  color: currentColor;
}

.choose-box {
  width: 100%;
  height: 100%;
  max-width: 400px;
  min-height: 400px;
  max-height: 400px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
  padding: 40px;
}
.choose-box .img-box {
  width: 280px;
  height: 280px;
  border-radius: 50%;
}
.choose-box .img-box img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.headprofile-box {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
}
.headprofile-box .headprofile {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
}
.headprofile-box .headprofile img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.headprofile-box .three-btn {
  background: #fcfcfc;
  border: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.headprofile-box .three-btn button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  color: #b5b5b5;
}
.headprofile-box .three-btn button:hover {
  color: #fbb522;
}

footer {
  padding: 40px 0;
}
footer a {
  text-decoration: none;
}
footer .ft-logobox {
  width: 180px;
  height: 80px;
  border-radius: 10px;
}
footer .ft-logobox img {
  width: 100%;
  max-width: 150px;
  height: 50px;
}
footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: 35px;
}
footer ul li a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}
footer ul li a:hover {
  color: #fbb522;
}
footer .ft-copytxt a {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}
footer .ft-copytxt a:hover {
  color: #fbb522;
  text-decoration: underline;
}

.left-aside {
  position: sticky;
  display: block;
  top: 80px;
  height: calc(100vh - 80px);
  width: 280px;
  background: #fcfcfc;
  overflow-y: auto;
  color: #4e2a14 !important;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}
.left-aside ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 60px;
}
.left-aside ul li .txt-btn {
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 20px;
  color: #4e2a14;
}
.left-aside ul li .txt-btn:hover,
.left-aside ul li .txt-btn :active {
  color: #fbb522 !important;
}

.number-card {
  background: #fcfcfc;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  padding: 15px;
  color: #6a738d;
  text-align: center;
}

.layout {
  display: flex;
}
.layout main {
  padding: 15px;
  width: 100%;
}

.border-btn {
  background: none;
  border: none;
  text-decoration: underline;
  font-size: 20px;
  font-weight: 600;
  color: #4e2a14;
}
.border-btn:hover {
  color: #fbb522;
}

.btn-yellow {
  background: #fbb522;
}
.btn-yellow:hover {
  background: #d59509;
  transition: 500ms;
  color: #ffffff;
}

.form-control,
.form-select,
.form-control:focus {
  box-shadow: 0 0 0 0 rgba(62, 213, 21, 0.575);
  border: 1px solid #4e2a14;
}

.list-table {
  border: 1px solid #4e2a14;
  text-align: center;
  color: #4e2a14;
}
.list-table thead tr th {
  border: none;
  border-bottom: 1px solid #4e2a14;
}
.list-table thead tr th:not(:last-child) {
  border-right: 1px solid #4e2a14;
}
.list-table tbody tr td {
  border: none;
}
.list-table tbody tr td:not(:last-child) {
  border-right: 1px solid #4e2a14;
}

.upload-box {
  background: #f2f6f9;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.drag-box {
  position: relative;
  display: block;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px 50px;
  border: 2px dashed rgba(148, 148, 148, 0.7);
  border-radius: 50%;
}
.drag-box span {
  position: relative;
  display: block;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #949494;
  pointer-events: none;
  padding-top: 10px;
  color: #6fb0eb;
}
.drag-box span a {
  color: #1881e1;
}
.drag-box input {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

* {
  box-sizing: border-box;
}

:root {
  --logo-left: 3%;
  --logo-top: 54%;
  --logo-rotator: rotate(0);
  --bg-color1: orange;
  --bg-color2: purple;
}

.canvas-wrapper {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.canvas-wrapper .canvas-rotator {
  position: absolute;
  width: 100%;
  height: 100%;
  width: 100%; /* Adjust the width as needed */
  height: 100%;
}
.canvas-wrapper .canvas-rotator canvas {
  z-index: 0;
  position: relative;
  left: 14px;
  top: 14px;
  border-radius: 50%;
}
.canvas-wrapper .canvas-rotator .canvas-gradientbg {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  --size: 276px;
  --stroke-size: 32px;
  --diff: calc(calc(var(--size) / 2) - var(--stroke-size));
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  -webkit-mask: radial-gradient(circle var(--diff), transparent 99%, #fff 100%);
  mask: radial-gradient(circle var(--diff), transparent 99%, #fff 100%);
  margin: auto;
  transform: rotate(-112deg);
  z-index: 0;
}
.canvas-wrapper .canvas-rotator .canvas-logo {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  left: var(--logo-left);
  top: var(--logo-top);
  border-radius: 50%;
  padding: 5px;
  z-index: 2;
  transform: var(--logo-rotator);
}
.canvas-wrapper .canvas-rotator .canvas-logo img {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
}
.canvas-wrapper .canvas-avatar {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  -o-object-fit: cover;
     object-fit: cover;
}

table {
  border-collapse: collapse;
  width: 100%;
}
table td {
  border: 1px solid #ccc;
  padding: 5px 10px;
}

.harvard-header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
}
.harvard-header .navbar {
  width: 100%;
  background: #dcc283;
  padding-top: 0;
  padding-bottom: 0;
}
.harvard-header .navbar .navbar-brand {
  height: 70px;
}
.harvard-header .navbar .navbar-brand img {
  width: 100%;
  height: 100%;
}
.harvard-header .harvard-btn button {
  background: #ffffff;
  border: 1px solid #2a2a2a;
  border-radius: 6px;
  padding: 5px;
  width: 90px;
}
.harvard-header .harvard-btn button:hover {
  border: 1px solid #a41c30;
  color: #a41c30;
}

.fs-50 {
  font-size: 50px !important;
}

@media screen and (max-width: 991px) {
  .nav {
    padding: 0;
  }
  .nav .nav-item {
    padding: 0px !important;
    color: #83818c !important;
    margin: 0;
    margin-bottom: 10px;
  }
  .nav .nav-item:nth-child(2).active {
    color: currentColor;
    color: #83818c !important;
  }
  .nav .nav-item:nth-child(2).active ~ .nav-indicator {
    background: none !important;
    width: none !important;
    left: 0 !important;
  }
  .nav-indicator {
    display: none;
  }
  .dropdown-box {
    margin-bottom: 20px;
  }
  .harvard-btn {
    padding-bottom: 20px;
  }
}
.filter-content {
  z-index: 1000 !important;
  position: absolute;
  width: 350px;
  text-align: start;
  background: white;
}
.filter-content .filter-search {
  max-width: 330px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filter-content .filter-search:hover {
  color: #0d6efd;
}
.filter-content img {
  width: 43px;
  height: 43px;
  -o-object-fit: cover;
     object-fit: cover;
}

.filter-content-mobile {
  z-index: 1000 !important;
  position: absolute;
  width: 800px;
  text-align: start;
  background: white;
  left: 22.5%;
  top: 75px;
}
.filter-content-mobile .filter-search {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filter-content-mobile .filter-search:hover {
  color: #0d6efd;
}
.filter-content-mobile .filter-search-not_found {
  max-width: 250px;
}
.filter-content-mobile .filter-search-not_found:hover {
  color: #0d6efd;
}
.filter-content-mobile img {
  width: 43px;
  height: 43px;
  -o-object-fit: cover;
     object-fit: cover;
}

.social-slider {
  width: 100%;
  max-height: 400px;
}
.social-slider .slider-box {
  width: 100%;
  max-height: 400px;
  aspect-ratio: 1/0.5;
  background-size: cover;
}
.social-slider .slider-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=App.css.map */